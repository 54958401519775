import React, { useEffect, useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import { addSeconds } from "date-fns";

import { protectedResources } from "../authConfig";
import {
  getVideoDirectoryList,
  checkModifiedSince,
} from "../Services/AzureBlobService";

const baseUri = process.env.REACT_APP_VIDEO_STREAMING_CONTAINER_URI as string;

type VideoSelectorProps = {
  onItemSelected: (item: string) => void;
};

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 320 },
};

function VideoSelector(props: VideoSelectorProps) {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [dropdownOptions, setDropdownOptions] = useState<
    Array<IDropdownOption>
  >([]);
  const [selectedKey, setSelectedKey] = useState<string | null>(null);

  useEffect(() => {
    const f = async () => {
      if (account && inProgress === "none") {
        try {
          const response = await instance.acquireTokenSilent({
            scopes: protectedResources.thermoImageStorageAccount.scopes,
            account: account,
          });
          const videoStartDateList = await getVideoDirectoryList(
            process.env.REACT_APP_VIDEO_STREAMING_CONTAINER_URI as string,
            response.accessToken
          );
          const lastVideoStartDate = videoStartDateList.sort((a, b) => {
            return parseInt(b) - parseInt(a);
          })[0];
          const lastViedoPlaylistUri = `${baseUri}/${lastVideoStartDate}/playlist.m3u8`;
          const modifiedSince10sec = await checkModifiedSince(
            lastViedoPlaylistUri,
            response.accessToken,
            addSeconds(new Date(), -10)
          );
          const fetchedDropdownOptions = videoStartDateList.map((v) => {
            let text = `${v.substring(0, 4)}/${v.substring(4, 6)}/${v.substring(
              6,
              8
            )} ${v.substring(8, 10)}:${v.substring(10, 12)}`;
            if (modifiedSince10sec && v === lastVideoStartDate) {
              text = text + " (リアルタイム配信中)";
            }
            return {
              key: v,
              text,
            };
          });
          setDropdownOptions(fetchedDropdownOptions);
          if (modifiedSince10sec) {
            setSelectedKey(lastVideoStartDate);
            props.onItemSelected(lastVideoStartDate);
          }
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect({
              scopes: protectedResources.thermoImageStorageAccount.scopes,
            });
          }
        }
      }
    };
    f();
    // eslint-disable-next-line
  }, [account, inProgress, instance]);

  return (
    <Dropdown
      options={dropdownOptions}
      placeholder="再生する動画の日時を選択してください。"
      styles={dropdownStyles}
      onChange={(e, dropdownOption) => {
        if (dropdownOption !== undefined) {
          props.onItemSelected(dropdownOption.key as string);
          setSelectedKey(dropdownOption.key as string);
          console.log(dropdownOption.key);
        }
      }}
      selectedKey={selectedKey}
    />
  );
}

export default VideoSelector;
