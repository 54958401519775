export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
    authority: process.env.REACT_APP_AUTH_AUTHORITY as string, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI as string,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [],
};

export const protectedResources = {
  sensorTelmetryApi: {
    scopes: [
      `${
        process.env.REACT_APP_SENSOR_TELEMETRY_API_APP_ID_URI as string
      }/user_impersonation`,
    ], // e.g. api://xxxxxx/access_as_user
  },
  thermoImageStorageAccount: {
    scopes: ["https://storage.azure.com/user_impersonation"],
  },
};
