import React, { useEffect, useMemo, useRef } from "react";
import Hls from "hls.js";
import { useAccount, useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

import { protectedResources } from "../authConfig";

type HlsPlayerProps = {
  playlistUri: string;
};

function HlsPlayer(props: HlsPlayerProps) {
  const isSupportedBrowser = useMemo(() => Hls.isSupported(), []);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    let hls: Hls;
    const f = async () => {
      if (
        isSupportedBrowser &&
        videoRef.current !== null &&
        account &&
        inProgress === "none"
      ) {
        try {
          const response = await instance.acquireTokenSilent({
            scopes: protectedResources.thermoImageStorageAccount.scopes,
            account: account,
          });
          hls = new Hls({
            xhrSetup: (xhr, url) => {
              xhr.setRequestHeader(
                "Authorization",
                `Bearer ${response.accessToken}`
              );
              xhr.setRequestHeader("x-ms-version", "2020-12-06");
            },
            liveSyncDurationCount: 0,
          });
          hls.loadSource(props.playlistUri);
          hls.attachMedia(videoRef.current);
          hls.on(Hls.Events.MEDIA_ATTACHED, function (event, data) {
            if (videoRef.current !== null) {
              videoRef.current.muted = true;
              videoRef.current.play();
            }
          });
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect({
              scopes: protectedResources.thermoImageStorageAccount.scopes,
            });
          }
        }
      }
    };
    f();

    return () => {
      if (hls !== undefined) {
        hls.removeAllListeners();
        hls.stopLoad();
      }
    };
    // eslint-disable-next-line
  }, [videoRef, account, inProgress, instance, props.playlistUri]);

  return (
    <div>
      {isSupportedBrowser && props.playlistUri !== "" && (
        <div>
          <video ref={videoRef} controls></video>
        </div>
      )}
      {!isSupportedBrowser && (
        <div>このブラウザでは動画再生をサポートしていません。</div>
      )}
    </div>
  );
}

export default HlsPlayer;
