import axios from "axios";

export const getImageAsBase64 = async (
  uri: string,
  accessToken: string
): Promise<string> => {
  const response = await axios.get(uri, {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "x-ms-version": "2020-12-06",
      "x-ms-date": new Date().toUTCString(),
    },
  });

  const blob = new Blob([response.data], { type: response.data.type });
  const image = window.URL.createObjectURL(blob);

  return image;
};

export const getVideoDirectoryList = async (
  containerUri: string,
  accessToken: string
): Promise<Array<string>> => {
  const videoList = new Array<string>();
  const reqUri = `${containerUri}/?restype=container&comp=list&delimiter=/`;
  const response = await axios.get(reqUri, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "x-ms-version": "2020-12-06",
      "x-ms-date": new Date().toUTCString(),
    },
  });
  const parser = new DOMParser();
  const xmlData = parser.parseFromString(response.data, "text/xml");
  const nameNodes = xmlData.querySelectorAll("Blobs BlobPrefix Name");

  nameNodes.forEach((nameNode) => {
    if (nameNode.textContent !== null) {
      videoList.push(nameNode.textContent.replace("/", ""));
    }
  });
  return videoList;
};

export const checkModifiedSince = async (
  blobUri: string,
  accessToken: string,
  modifiedsince: Date
): Promise<boolean> => {
  const reqUri = `${blobUri}?comp=metadata`;
  const response = await axios.get(reqUri, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "x-ms-version": "2020-12-06",
      "x-ms-date": new Date().toUTCString(),
      "If-Modified-Since": modifiedsince.toUTCString(),
    },
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 304,
  });
  if (response.status === 304) {
    return false;
  } else {
    return true;
  }
};
