import { LineProps } from "recharts";
import { format } from "date-fns";

import { SensorTelemetry } from "../Models/SensorTelemetry";

export const bedSideSensorLineProps: Array<LineProps> = [
  {
    dataKey: "sensor0_temperature",
    yAxisId: "temperature",
    name: "ベッドサイド気温",
    unit: "℃",
    stroke: "#C7243A",
  },
  {
    dataKey: "sensor0_humidity",
    yAxisId: "humidity",
    name: "ベッドサイド湿度",
    unit: "%",
    stroke: "#3261AB",
  },
];

export const thermoCameraLineProps: Array<LineProps> = [
  {
    dataKey: "thermo_max",
    yAxisId: "temperature",
    name: "赤外線温度カメラ 最大値",
    unit: "℃",
    stroke: "#EDAD0B",
  },
  {
    dataKey: "thermo_min",
    yAxisId: "temperature",
    name: "赤外線温度カメラ 最低値",
    unit: "℃",
    stroke: "#23AC0E",
  },
  {
    dataKey: "thermo_avg",
    yAxisId: "temperature",
    name: "赤外線温度カメラ 平均値",
    unit: "℃",
    stroke: "#3261AB",
  },
  {
    dataKey: "thermo_med",
    yAxisId: "temperature",
    name: "赤外線温度カメラ 中央値",
    unit: "℃",
    stroke: "#B61972",
  },
];

export const generateChartData = (
  telemetries: Array<SensorTelemetry>,
  lineProps: Array<LineProps>
) => {
  return telemetries
    .map((t) => {
      return {
        ...t,
        time: format(new Date(t.enqueuedTime), "yyyy-MM-dd HH:mm:ss"),
      };
    })
    .map((t) => {
      lineProps.forEach((p) => {
        const telemetryValue = t[p.dataKey as keyof typeof t];
        if (telemetryValue === 0) {
          (t as any)[p.dataKey as keyof typeof t] = null;
        }
        if (
          p.yAxisId === "temperature" &&
          telemetryValue !== null &&
          telemetryValue > 50
        ) {
          (t as any)[p.dataKey as keyof typeof t] = null;
        }
      });
      return t;
    });
};
