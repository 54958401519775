import React from "react";
import { FontSizes } from "@fluentui/theme";
import { getTheme } from "@fluentui/react";

function Header() {
  const theme = getTheme();
  return (
    <div
      style={{
        fontSize: FontSizes.large,
        fontWeight: "bold",
        backgroundColor: theme.palette.themePrimary,
        color: theme.palette.white,
        display: "flex",
        alignItems: "center",
        padding: 10,
      }}
    >
      Nagi System (β ver)
    </div>
  );
}

export default Header;
