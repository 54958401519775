import { Nav, INavStyles, INavLinkGroup } from "@fluentui/react/lib/Nav";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const navStyles: Partial<INavStyles> = {
  root: { width: 180 },
};

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: "動画配信",
        url: "/video",
        key: "video",
        icon: "video",
        target: "_blank",
      },
      {
        name: "環境測定",
        url: "/telemetry",
        key: "telemetry",
        icon: "LineChart",
        target: "_blank",
      },
    ],
  },
];

// https://github.com/microsoft/fluentui/issues/915
function Navigation() {
  const navigate = useNavigate();

  const [selectedKey, setSelectedKey] = useState<string>("video");

  return (
    <Nav
      onLinkClick={(event, element) => {
        if (event !== undefined && element !== undefined) {
          event.preventDefault();
          navigate(element.url, { replace: true });
          setSelectedKey(element.key ?? "video");
        }
      }}
      styles={navStyles}
      ariaLabel="Nav basic example"
      groups={navLinkGroups}
      selectedKey={selectedKey}
    />
  );
}

export default Navigation;
