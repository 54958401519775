import axios from "axios";
import { SensorTelemetry } from "../Models/SensorTelemetry";

export const getSensorTelemetries = async (
  startDate: Date,
  endDate: Date,
  accessToken: string
): Promise<Array<SensorTelemetry>> => {
  const response = await axios.post(
    process.env.REACT_APP_SENSOR_TELEMETRY_API_URI as string,
    {
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
};
