import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Stack } from "@fluentui/react";

import Header from "./Components/Header";
import Navigation from "./Components/Navigation";
import TelemetryDashboard from "./Components/TelemetryDashboard";
import VideoPlayer from "./Components/VideoPlayer";

export const App: React.FunctionComponent = () => {
  return (
    <Stack>
      <Stack.Item>
        <Header />
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item>
            <Navigation />
          </Stack.Item>
          <Stack.Item grow={1}>
            <Routes>
              <Route path="/" element={<VideoPlayer />} />
              <Route path="video" element={<VideoPlayer />} />
              <Route path="telemetry" element={<TelemetryDashboard />} />
            </Routes>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default App;
