import { useState } from "react";

import HlsPlayer from "../Components/HlsPlayer";
import VideoSelector from "../Components/VideoSelector";

const baseUri = process.env.REACT_APP_VIDEO_STREAMING_CONTAINER_URI as string;

function VideoPlayer() {
  const [playListUri, setPlayListUri] = useState("");

  return (
    <div
      className="VideoPlayerContainer"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="VidoPlayerHeader" style={{ padding: 10 }}>
        <VideoSelector
          onItemSelected={(item) => {
            setPlayListUri(`${baseUri}/${item}/playlist.m3u8`);
          }}
        />
      </div>
      <div className="VideoPlayerContent" style={{ flex: 1, padding: 10 }}>
        <div style={{ backgroundColor: "gray" }}></div>
        <HlsPlayer playlistUri={playListUri} />
      </div>
    </div>
  );
}

export default VideoPlayer;
