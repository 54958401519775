import React, { useEffect, useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
} from "recharts";
import { startOfToday, addHours, format } from "date-fns";
import {
  DatePicker,
  DayOfWeek,
  defaultDatePickerStrings,
} from "@fluentui/react";

import { SensorTelemetry } from "../Models/SensorTelemetry";
import { getSensorTelemetries } from "../Services/SensorTelemetryService";
import {
  bedSideSensorLineProps,
  generateChartData,
  thermoCameraLineProps,
} from "../Helpers/ChartDataHelper";
import { protectedResources } from "../authConfig";
import { getImageAsBase64 } from "../Services/AzureBlobService";

function TelemetryDashboard() {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [startDate, setStartDate] = useState<Date>(startOfToday());
  const [data, setData] = useState<any[]>([]);
  const [selectedDataPoint, setSelectedDataPoint] = useState<SensorTelemetry>();
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const f = async () => {
      if (account && inProgress === "none") {
        try {
          const response = await instance.acquireTokenSilent({
            scopes: protectedResources.sensorTelmetryApi.scopes,
            account: account,
          });
          const telemetries = await getSensorTelemetries(
            startDate,
            addHours(startDate, 24),
            response.accessToken
          );
          setData(
            generateChartData(
              telemetries,
              bedSideSensorLineProps.concat(thermoCameraLineProps)
            )
          );
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect({
              scopes: protectedResources.sensorTelmetryApi.scopes,
            });
          }
        }
      }
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, account, inProgress, instance]);

  useEffect(() => {
    const f = async () => {
      if (
        account &&
        inProgress === "none" &&
        selectedDataPoint !== undefined &&
        selectedDataPoint.thermo_img_blob_uri !== null
      ) {
        try {
          const response = await instance.acquireTokenSilent({
            scopes: protectedResources.thermoImageStorageAccount.scopes,
            account: account,
          });
          const base64Image = await getImageAsBase64(
            selectedDataPoint.thermo_img_blob_uri,
            response.accessToken
          );
          setImageSrc(base64Image);
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect({
              scopes: protectedResources.sensorTelmetryApi.scopes,
            });
          }
        }
      }
    };
    f();
  }, [selectedDataPoint, account, inProgress, instance]);

  return (
    <div
      className="DashboardContainer"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="DashboardHeader" style={{ width: 200, margin: 10 }}>
        <DatePicker
          firstDayOfWeek={DayOfWeek.Sunday}
          placeholder="Select a date..."
          ariaLabel="Select a date"
          strings={defaultDatePickerStrings}
          onSelectDate={(date) => {
            console.log(date);
            if (date) {
              setStartDate(date);
              setSelectedDataPoint(undefined);
            }
          }}
          formatDate={(date?: Date): string => {
            return date?.toLocaleDateString() ?? "";
          }}
          value={startDate}
        />
      </div>
      <div
        className="DashboardContent"
        style={{ display: "flex", flexDirection: "row", margin: 10 }}
      >
        <div className="DashboardLeftContent" style={{ flex: 2 }}>
          {data.length > 0 && (
            <div>
              <ResponsiveContainer width="99%" height={240}>
                <LineChart
                  data={data}
                  syncId="line"
                  onClick={(e) => {
                    if (
                      e.activePayload !== undefined &&
                      e.activePayload.length > 0
                    ) {
                      const payload: SensorTelemetry =
                        e.activePayload[0].payload;
                      setSelectedDataPoint(payload);
                    }
                  }}
                >
                  <CartesianGrid />
                  <XAxis
                    dataKey="time"
                    domain={["dataMin", "dataMax"]}
                    tickFormatter={(t) => format(new Date(t), "HH:mm")}
                    fontSize={"12px"}
                  />
                  <YAxis
                    yAxisId="temperature"
                    orientation="left"
                    fontSize={"12px"}
                    domain={[15, 40]}
                    tickFormatter={(t) => `${t}℃`}
                    ticks={[15, 20, 25, 30, 35, 40]}
                  />
                  <YAxis
                    yAxisId="humidity"
                    orientation="right"
                    fontSize={"12px"}
                    domain={[0, 100]}
                    tickFormatter={(t) => `${t}%`}
                    ticks={[0, 20, 40, 60, 80, 100]}
                  />
                  <Tooltip wrapperStyle={{ fontSize: "8px" }} />
                  <Legend
                    verticalAlign="top"
                    layout="horizontal"
                    align="center"
                    wrapperStyle={{ fontSize: "12px" }}
                  />
                  {bedSideSensorLineProps.map((s, i) => (
                    <Line
                      key={i}
                      type="monotone"
                      dataKey={s.dataKey}
                      yAxisId={s.yAxisId}
                      name={s.name}
                      unit={s.unit}
                      stroke={s.stroke}
                      dot={false}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
              <ResponsiveContainer width="99%" height={240}>
                <LineChart
                  data={data}
                  syncId="line"
                  onClick={(e) => {
                    if (
                      e.activePayload !== undefined &&
                      e.activePayload.length > 0
                    ) {
                      const payload: SensorTelemetry =
                        e.activePayload[0].payload;
                      setSelectedDataPoint(payload);
                    }
                  }}
                >
                  <CartesianGrid />
                  <XAxis
                    dataKey="time"
                    domain={["dataMin", "dataMax"]}
                    tickFormatter={(t) => format(new Date(t), "HH:mm")}
                    fontSize={"12px"}
                  />
                  <YAxis
                    yAxisId="temperature"
                    orientation="left"
                    fontSize={"12px"}
                    domain={[15, 40]}
                    tickFormatter={(t) => `${t}℃`}
                    ticks={[15, 20, 25, 30, 35, 40]}
                  />
                  <YAxis
                    yAxisId="temperture"
                    orientation="right"
                    fontSize={"12px"}
                    domain={[15, 40]}
                    tickFormatter={(t) => `${t}℃`}
                    ticks={[15, 20, 25, 30, 35, 40]}
                  />
                  <Tooltip wrapperStyle={{ fontSize: "8px" }} />
                  <Legend
                    verticalAlign="top"
                    layout="horizontal"
                    align="center"
                    wrapperStyle={{ fontSize: "12px" }}
                  />
                  {thermoCameraLineProps.map((s, i) => (
                    <Line
                      key={i}
                      type="monotone"
                      dataKey={s.dataKey}
                      yAxisId={s.yAxisId}
                      name={s.name}
                      unit={s.unit}
                      stroke={s.stroke}
                      dot={false}
                    />
                  ))}
                  <Brush height={20} dataKey="time" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
          {data.length === 0 && (
            <div style={{ margin: 0 }}>
              選択した日にデータが存在しません。他の日を選択してください。
            </div>
          )}
        </div>
        <div className="DashboardRightContent" style={{ flex: 1 }}>
          {selectedDataPoint !== undefined && (
            <img src={imageSrc} alt="温度カメラの撮影画像" width="100%" />
          )}
        </div>
      </div>
    </div>
  );
}

export default TelemetryDashboard;
